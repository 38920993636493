<template>
  <div class="change_password">
    <el-form
      :rules="rules"
      ref="ChangePassword"
      :label-position="labelPosition"
      :model="valuesPassword"
      size="medium"
      @submit.native.prevent="onEnter"
      class=""
    >
      <el-form-item label="Текущий пароль:" prop="password">
        <el-input
          type="password"
          v-model="valuesPassword.password"
          placeholder="************"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="Новый пароль:" prop="newpassword">
        <el-input
          type="password"
          v-model="valuesPassword.newpassword"
          placeholder="************"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="Повтор нового пароля:" prop="newpasswordconfirm">
        <el-input
          type="password"
          v-model="valuesPassword.newpasswordconfirm"
          placeholder="************"
          show-password
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <div class="btn_right_form">
        <el-button
          native-type="reset"
          size="medium"
          @click.prevent="resetForm"
          :disabled="send_data"
          plain
          >Сбросить форму</el-button
        >
        <el-button
          native-type="submit"
          size="medium"
          class="btn_110"
          @click.prevent="submitForm"
          :loading="send_data"
          plain
          >Изменить</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SettingsChangePassword",
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value.trim() !== this.valuesPassword.newpassword) {
        callback(new Error("Не совпадает с новым паролем!"));
      } else {
        callback();
      }
    };
    return {
      send_data: false,
      valuesPassword: {
        password: "",
        newpassword: "",
        newpasswordconfirm: "",
      },
      labelPosition: "top",
      rules: {
        password: [
          {
            required: true,
            message: "Пожалуйста введите пароль",
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: "Длинна пароля от 6 до 20 символов",
            trigger: "blur",
          },
        ],
        newpassword: [
          {
            required: true,
            message: "Пожалуйста введите пароль",
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: "Длинна пароля от 6 до 20 символов",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
        ],
        newpasswordconfirm: [
          {
            required: true,
            message: "Пожалуйста введите пароль",
            trigger: "change",
          },
          {
            min: 6,
            max: 20,
            message: "Длинна пароля от 6 до 20 символов",
            trigger: "blur",
            transform(value) {
              return value.trim();
            },
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      //todo костыль для демо
      if (this.$store.getters["Main/contactID"] === 25694) {
        this.$notify({
          title: "Error",
          message: "Это демонстрационная версия, изменения запрещены!",
          type: "error",
          offset: 85,
          duration: 6000,
        });
        return false;
      }
      this.$refs["ChangePassword"].validate((valid) => {
        if (valid) {
          this.updatePassword();
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["ChangePassword"].resetFields();
    },
    onEnter() {
      this.submitForm();
    },
    updatePassword() {
      this.send_data = true;
      this.$store
        .dispatch("Settings/customerUserPassword", this.valuesPassword)
        .then((response) => {
          if (response.data.status === 2000) {
            this.$notify({
              title: "Success",
              message: "Пароль успешно изменен!",
              type: "success",
              offset: 85,
              duration: 6000,
            });
            this.resetForm();
          }
        })
        .finally(() => {
          this.send_data = false;
        });
    },
  },
};
</script>

<style scoped></style>
