<template>
  <div class="my_settings" v-show="!send_data">
    <el-form
      :rules="rules"
      ref="MySettings"
      :label-position="labelPosition"
      :model="valuesSettings"
      size="medium"
      @submit.native.prevent="onEnter"
      class=""
      :disabled="send_data"
    >
      <el-form-item label="Фамилия:" prop="LastName">
        <el-input
          v-model="valuesSettings.LastName"
          placeholder="Иванов"
        ></el-input>
      </el-form-item>
      <el-form-item label="Имя:" prop="Firstname">
        <el-input
          v-model="valuesSettings.Firstname"
          placeholder="Иван"
        ></el-input>
      </el-form-item>
      <el-form-item label="Отчество:" prop="MiddleName">
        <el-input
          v-model="valuesSettings.MiddleName"
          placeholder="Иванович"
        ></el-input>
      </el-form-item>
      <el-form-item label="Телефон:" prop="Phone">
        <el-input
          type="phone"
          v-model="valuesSettings.Phone"
          v-mask="'+7 ~## ### ## ##'"
          placeholder="+7 900 000 00 00"
        ></el-input>
      </el-form-item>
      <el-form-item label="E-mail:" prop="Email">
        <el-input
          type="email"
          v-model="valuesSettings.Email"
          placeholder="ivan@ivanov.ru"
        ></el-input>
      </el-form-item>
      <div class="btn_right_form">
        <el-button
          native-type="submit"
          size="medium"
          class="btn_110"
          @click.prevent="submitForm('MySettings')"
          :loading="send_data"
          plain
          >Изменить</el-button
        >
      </div>
    </el-form>
    <div class="switch_settings_block">
      <el-switch
        :width="switchWidth"
        v-model="valuesSettings.InfoSMS"
        active-text="СМС уведомления"
      />
      <el-switch
        :width="switchWidth"
        v-model="valuesSettings.InfoEmail"
        active-text="E-mail уведомления"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsMySettings",
  data() {
    // let validateFIO = (rule, value, callback) => {
    //   if (this.regFio(value) || value === "") {
    //     callback();
    //   } else {
    //     callback(new Error("ФИО начинается с заглавной"));
    //   }
    // };
    let validateEmail = (rule, value, callback) => {
      if (this.regEmail(value) || value === "") {
        callback();
      } else {
        callback(new Error("Неверный формат Email"));
      }
    };
    return {
      valuesSettings: {
        LastName: "",
        Firstname: "",
        MiddleName: "",
        Phone: "",
        Email: "",
        InfoEmail: false,
        InfoSMS: false,
      },
      labelPosition: "top",
      rules: {
        LastName: [
          {
            required: true,
            message: "Пожалуйста введите Фамилию",
            trigger: "change",
          },
          {
            min: 3,
            max: 15,
            message: "Длинна Фамилии от 3 до 15 символов",
            trigger: "blur",
          },
          // {
          //   validator: validateFIO,
          //   trigger: "change",
          //   transform(value) {
          //     return value.trim();
          //   },
          // },
        ],
        Firstname: [
          {
            required: true,
            message: "Пожалуйста введите Имя",
            trigger: "change",
          },
          {
            min: 3,
            max: 15,
            message: "Длинна Имени от 3 до 15 символов",
            trigger: "blur",
          },
          // {
          //   validator: validateFIO,
          //   trigger: "change",
          //   transform(value) {
          //     return value.trim();
          //   },
          // },
        ],
        MiddleName: [
          {
            min: 3,
            max: 15,
            message: "Длинна от 3 до 15 символов",
            trigger: "blur",
          },
          // {
          //   validator: validateFIO,
          //   trigger: "change",
          //   transform(value) {
          //     return value.trim();
          //   },
          // },
        ],
        Phone: [
          {
            required: true,
            message: "Пожалуйста укажите телефон",
            trigger: "change",
          },
          {
            min: 11,
            message: "Формат телефона: +7 900 000 00 00 (11 цифр)",
            trigger: "change",
            transform(value) {
              return value.trim().replace(/\+7/, "7").replace(/\s/g, "");
            },
          },
        ],
        Email: [
          {
            validator: validateEmail,
            trigger: "change",
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
      switchWidth: 32,
      send_data: false,
    };
  },
  computed: {},
  methods: {
    regFio(value) {
      var regExp = /^([А-ЯA-Z]|[А-ЯA-Z][\x27а-яa-z]{1,})?$/;
      return regExp.test(value);
    },
    regEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submitForm(formName) {
      //todo костыль для демо
      if (this.$store.getters["Main/contactID"] === 25694) {
        this.$notify({
          title: "Error",
          message: "Это демонстрационная версия, изменения запрещены!",
          type: "error",
          offset: 85,
          duration: 6000,
        });
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateUserInfo();
        } else {
          return false;
        }
      });
    },
    onEnter() {
      this.submitForm(this.formName);
    },
    updateUserInfo() {
      this.send_data = true;
      this.$store
        .dispatch("Settings/setUserInfo", this.valuesSettings)
        .then((response) => {
          if (response.data.status && response.data.status === 2000) {
            this.$notify({
              title: "Success",
              message: "Данные успешно сохранены!",
              type: "success",
              offset: 85,
              duration: 6000,
            });
          }
        })
        .finally(() => {
          this.send_data = false;
        });
    },
  },
  mounted() {
    this.send_data = true;
    this.$store
      .dispatch("Settings/getUserInfo")
      .then((response) => {
        if (response.data.response && response.data.status === 2000) {
          this.valuesSettings.LastName = response.data.response.LastName;
          this.valuesSettings.Firstname = response.data.response.Firstname;
          this.valuesSettings.MiddleName = response.data.response.MiddleName;
          this.valuesSettings.Phone = response.data.response.Phone;
          this.valuesSettings.Email = response.data.response.Email;
          this.valuesSettings.InfoSMS = response.data.response.InfoSMS;
          this.valuesSettings.InfoEmail = response.data.response.InfoEmail;
        }
      })
      .finally(() => {
        this.send_data = false;
        this.$emit("loadingEvent", false);
      });
  },
  beforeCreate() {
    this.$emit("loadingEvent", true);
  },
};
</script>

<style scoped></style>
