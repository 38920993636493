<template>
  <div class="users_block" v-show="show">
    <div class="users_block_header">
      <el-button
        size="medium"
        class="btn_110"
        @click.prevent="showModal"
        :loading="false"
        plain
        >Добавить</el-button
      >
    </div>
    <div class="users_block_list">
      <!--      <user-card-->
      <!--        v-for="(user, index) in users"-->
      <!--        :key="index"-->
      <!--        :user="user"-->
      <!--        @editUser="editUser(user)"-->
      <!--      />-->
      <user-card
        v-for="(user, index) in UserList"
        :key="index"
        :user="user"
        @editUser="editUser(user)"
      />
    </div>
    <modal
      ref="modalAddUser"
      modal-body="AddUser"
      title="Добавление нового пользователя компании"
      :show-logo="true"
    >
    </modal>
    <modal
      ref="modalEditUser"
      modal-body="EditUser"
      title="Редактирование пользователя компании"
      :show-logo="true"
      :props-form="infoUserForModal"
    >
    </modal>
  </div>
</template>

<script>
import UserCard from "@/components/Settings/SettingsUsersCard";
import Modal from "@/components/Modal";

export default {
  name: "SettingsUsers",
  components: { Modal, UserCard },
  data() {
    return {
      infoUserForModal: {},
      loading: {},
      load: true,
      show: false,
    };
  },
  computed: {
    UserList() {
      return this.$store.getters["Settings/UserList"];
    },
  },
  methods: {
    showModal: function () {
      this.$refs.modalAddUser.show = true;
    },
    addUser(values) {
      this.users.push(values);
    },
    editUser(user) {
      this.infoUserForModal = user;
      this.$refs.modalEditUser.show = true;
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".users_block_list",
        text: text,
        spinner: "el-icon-loading",
        background: "rgb(251, 251, 252)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
  },
  mounted() {
    // this.openLoading("Загрузка пользователей...");
    // this.$store.dispatch("Settings/customerUserList").finally(() => {
    //   this.closeLoading();
    // });
    this.$emit("loadingEvent", true);
    this.$store.dispatch("Settings/customerUserList").finally(() => {
      this.$emit("loadingEvent", false);
      this.show = true;
    });
  },
  destroyed() {
    this.$store.commit("Settings/UserList", {});
  },
};
</script>
