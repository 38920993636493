<template>
  <div class="delivery_address_block">
    <div class="delivery_address_block_header">
      <el-button
        size="medium"
        class="btn_110"
        @click.prevent="showModal"
        :loading="false"
        plain
        >Добавить</el-button
      >
    </div>
    <div class="delivery_address_block_list">
      <div class="delivery_address_card_grid">
        <div class="delivery_address_card">
          <p>г. Екатеринбург</p>
          <p>пер. Северный, 2А - 703</p>
          <p>Комментарий:</p>
          <p>
            Вход в офисный центр под вывеской "ИНСИС" Вход в офисный центр под
            вывеской "ИНСИС" Вход в офисный центр под вывеской "ИНСИС"
          </p>
        </div>
        <div class="delivery_address_card_management">
          <div class="management_block">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить адрес доставки?"
            >
              <i
                class="el-icon-delete management_icon icon_hover_delete"
                slot="reference"
              ></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="delivery_address_card_grid">
        <div class="delivery_address_card">
          <p>г. Екатеринбург</p>
          <p>пер. Северный, 2А - 703</p>
          <p>Комментарий:</p>
          <p>Вход в офисный центр под вывеской "ИНСИС"</p>
        </div>
        <div class="delivery_address_card_management">
          <div class="management_block">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить адрес доставки?"
            >
              <i
                class="el-icon-delete management_icon icon_hover_delete"
                slot="reference"
              ></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="delivery_address_card_grid">
        <div class="delivery_address_card">
          <p>г. Екатеринбург</p>
          <p>пер. Северный, 2А - 703</p>
        </div>
        <div class="delivery_address_card_management">
          <div class="management_block">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить адрес доставки?"
            >
              <i
                class="el-icon-delete management_icon icon_hover_delete"
                slot="reference"
              ></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="delivery_address_card_grid">
        <div class="delivery_address_card">
          <p>г. Екатеринбург</p>
          <p>пер. Северный, 2А - 703</p>
          <p>Комментарий:</p>
          <p>Вход в офисный центр под вывеской "ИНСИС"</p>
        </div>
        <div class="delivery_address_card_management">
          <div class="management_block">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить адрес доставки?"
            >
              <i
                class="el-icon-delete management_icon icon_hover_delete"
                slot="reference"
              ></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="delivery_address_card_grid">
        <div class="delivery_address_card">
          <p>г. Екатеринбург</p>
          <p>пер. Северный, 2А - 703</p>
          <p>Комментарий:</p>
          <p>
            Вход в офисный центр под вывеской "ИНСИС" Вход в офисный центр под
            вывеской "ИНСИС" Вход в офисный центр под вывеской "ИНСИС"
          </p>
        </div>
        <div class="delivery_address_card_management">
          <div class="management_block">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить адрес доставки?"
            >
              <i
                class="el-icon-delete management_icon icon_hover_delete"
                slot="reference"
              ></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="delivery_address_card_grid">
        <div class="delivery_address_card">
          <p>г. Екатеринбург</p>
          <p>пер. Северный, 2А - 703</p>
          <p>Комментарий:</p>
          <p>Вход в офисный центр под вывеской "ИНСИС"</p>
        </div>
        <div class="delivery_address_card_management">
          <div class="management_block">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить адрес доставки?"
            >
              <i
                class="el-icon-delete management_icon icon_hover_delete"
                slot="reference"
              ></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <modal
      ref="modalAddMyDeliveryAddress"
      title="Добавить новый"
      subtitle="адрес доставки"
      :show-logo="true"
      modal-body="AddMyDeliveryAddress"
    >
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  name: "SettingsDeliveryAddress",
  components: { Modal },
  methods: {
    showModal: function () {
      this.$refs.modalAddMyDeliveryAddress.show = true;
    },
  },
};
</script>

<style scoped></style>
