<template>
  <div
    class="user_card_grid"
    :class="[isActiveUser ? '' : 'inactive_card']"
    :id="'user_' + user.id"
  >
    <div class="user_card">
      <div class="user_card_name">{{ user.name }} {{ user.surname }}</div>
      <div class="user_card_phone">{{ user.phone }}</div>
      <div class="user_card_email">{{ user.email }}</div>
      <div class="user_card_info">login: {{ user.login }}</div>
    </div>
    <div class="user_card_management">
      <div class="management_block">
        <el-switch
          :width="switchWidth"
          v-model="isActiveUser"
          @change="activeUser($event)"
          title="Вкл/Выкл пользователя"
        />
      </div>
      <div class="dividing_line"></div>
      <div class="management_block">
        <i
          class="el-icon-edit management_icon icon_hover_edit"
          @click="editUser"
          title="Редактировать пользователя"
        ></i>
      </div>
      <div class="dividing_line"></div>
      <div class="management_block">
        <el-popconfirm
          confirm-button-text="Да"
          cancel-button-text="Нет"
          icon="el-icon-delete"
          icon-color="red"
          title="Хотите удалить пользователя?"
          @confirm="deleteUser"
        >
          <i
            class="el-icon-delete management_icon icon_hover_delete"
            slot="reference"
            title="Удалить пользователя"
          ></i>
        </el-popconfirm>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SettingsUsersCard",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      switchWidth: 32,
      isActiveUser: true,
      loading: {},
    };
  },
  computed: {
    colorLoading() {
      if (this.isActiveUser === true) return "rgba(255, 255, 255, 0.8)";
      else return "rgba(242, 242, 242, 0.8)";
    },
  },
  methods: {
    editUser() {
      this.$emit("editUser", this.user);
    },
    deleteUser() {
      this.openLoading("Удаляем...");
      this.$store
        .dispatch("Settings/customerUserDelete", this.user.id)

        .finally(() => {
          this.closeLoading();
        });
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: "#user_" + this.user.id,
        text: text,
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 1)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
    activeUser: function (value) {
      let vm = this;
      this.$store.dispatch("Settings/customerUserOnline", {
        user_id: vm.user.id,
        online: value,
      });
      // .then((response) => {
      //   if (response.data.status && response.data.status !== 2000) {
      //     this.isActiveUser = !value;
      //   }
      // });
    },
  },
  mounted() {
    this.isActiveUser = this.user.online;
  },
};
</script>
