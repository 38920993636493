<template>
  <div
    class="settings_block_main_left"
    v-loading="Links.length === 0"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgb(251, 251, 252)"
  >
    <nav class="settings_menu">
      <router-link
        v-for="link in Links"
        :key="link.url.name"
        :to="link.url"
        active-class="active_link"
        class="settings_menu_link"
        >{{ link.title }}
      </router-link>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SettingsNav",
  data() {
    return {
      menu_links: [
        { title: "Мои настройки", url: { name: "my_settings" }, show: true },
        { title: "Смена пароля", url: { name: "change_password" }, show: true },
        { title: "Пользователи", url: { name: "users" }, show: false },
        {
          title: "Юридические лица",
          url: { name: "legal_entities" },
          show: false,
        },
        {
          title: "Логотип на этикетке",
          url: { name: "logo_on_label" },
          show: false,
        },
        // {
        //   title: "Адреса доставки",
        //   url: { name: "delivery_address" },
        //   show: true,
        // },
      ],
      Links: [],
      loading: false,
    };
  },
  methods: {
    menuLinks() {
      if (this.$store.getters["Main/IsAdmin"]) {
        this.Links = this.menu_links;
      } else {
        this.Links = this.menu_links.filter((link) => {
          // //todo костыль для демо
          // if (this.$store.getters["Main/contactID"] === 25694) {
          //   if (link.show === true && link.url.name !== "change_password")
          //     return link;
          // } else {
          //   if (link.show === true) return link;
          // }
          if (link.show === true) return link;
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("Main/checkToken").finally(() => {
      this.menuLinks();
    });
  },
};
</script>
<style lang="sass"></style>
