<template>
  <div class="logo_on_label_block" v-show="show">
    <div class="logo_on_label_block_header">
      <p>Пожалуйста, загрузите логотип размером</p>
      <p>250x250 px. в формате *.jpg</p>
    </div>
    <div v-if="customerLogo" class="logo_on_label_block_upload">
      <div class="logo-loaded">
        <div class="logo">
          <img :src="customerLogo" />
          <span class="logo-uploader_info">
            <el-popconfirm
              confirm-button-text="Да"
              cancel-button-text="Нет"
              icon="el-icon-delete"
              icon-color="red"
              title="Хотите удалить логотип?"
              @confirm="deleteLogo"
            >
              <i class="el-icon-delete logo-delete-icon" slot="reference"></i>
            </el-popconfirm>
          </span>
        </div>
      </div>
    </div>
    <div v-else class="logo_on_label_block_upload">
      <el-upload
        class="logo-uploader"
        action="https://api.gprint.ru/?action=customerFileUploadLogo"
        :headers="headersUpload"
        :data="dataUpload"
        :show-file-list="false"
        :on-remove="handleRemove"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <i class="el-icon-plus logo-uploader-icon"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsLogoOnLabel",
  data() {
    return {
      imageUrl: "",
      headersUpload: {
        Authorization: "Bearer 30915f02-b0e8-4066-8540-1244d47d7304",
        Token: sessionStorage.getItem("AuthToken"),
      },
      dataUpload: {
        oid: 5545454,
      },
      show: false,
    };
  },
  computed: {
    customerLogo() {
      return this.$store.getters["Settings/customerLogo"];
    },
  },
  methods: {
    handleAvatarSuccess(res, file) {
      if (res.status === 2000) {
        this.$store
          .dispatch("Settings/customerFileGetLogo")
          .then((response) => {
            if (
              response.data.status !== undefined &&
              response.data.status === 2000 &&
              response.data.response.customerLogo !== undefined
            ) {
              this.$store.commit(
                "Settings/customerLogo",
                response.data.response.customerLogo
              );
            }
          });
      }
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message({
          message: "Разрешены файлы только в jpg формате",
          type: "error",
          offset: 75,
        });
      }
      if (!isLt5M) {
        this.$message({
          message: "Максимально разрешенный размер 5Мб",
          type: "error",
          offset: 75,
        });
      }
      return isJPG && isLt5M;
    },
    handleRemove() {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload() {},
    deleteLogo() {
      this.$store
        .dispatch("Settings/customerFileDeleteLogo")
        .then((response) => {
          if (
            response.data.status !== undefined &&
            response.data.status === 2000
          ) {
            this.$store.commit("Settings/customerLogo", "");
          }
        });
    },
  },
  mounted() {
    this.$emit("loadingEvent", true);
    this.$store.dispatch("Settings/customerFileGetLogo").finally(() => {
      this.$emit("loadingEvent", false);
      this.show = true;
    });
  },
};
</script>

<style>
.logo-uploader .el-upload {
  border: 1px dashed #dcdfe6;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.logo-loaded {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid #dcdfe6;
}

.logo-uploader .el-upload:hover {
  border-color: #ea4f00;
}
.logo-uploader .el-upload:hover .logo-uploader-icon {
  color: #ea4f00;
}
.logo-loaded .logo-uploader_info:hover {
  opacity: 1;
}

.logo-uploader_info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.logo-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.logo-delete-icon {
  font-size: 28px;
  color: #fff;
  transition: color 0.15s linear;
  cursor: pointer;
}
.logo-delete-icon:hover {
  color: red;
}

.logo {
  width: 148px;
  height: 148px;
  display: block;
}
.logo img {
  width: 148px;
  height: 148px;
  display: block;
  object-fit: contain;
}
.el-upload-list--picture-card .el-upload-list__item-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}
.el-upload-list--picture-card .el-upload-list__item-actions::after {
  display: inline-block;
  content: "";
  height: 100%;
  vertical-align: middle;
}
.el-upload-list--picture-card .el-upload-list__item-actions span {
  display: none;
  cursor: pointer;
}
.el-upload-list--picture-card .el-upload-list__item-actions span + span {
  margin-left: 15px;
}
.el-upload-list--picture-card
  .el-upload-list__item-actions
  .el-upload-list__item-delete {
  position: static;
  font-size: inherit;
  color: inherit;
}
.el-upload-list--picture-card .el-upload-list__item-actions:hover {
  opacity: 1;
}
.el-upload-list--picture-card .el-upload-list__item-actions:hover span {
  display: inline-block;
}
</style>
