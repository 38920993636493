<template>
  <div class="legal_entity_block" v-show="show">
    <div class="legal_entity_block_header">
      <div class="legal_entity_block_header_name">
        {{ customerName }}
      </div>
      <div class="legal_entity_block_header_btn">
        <el-button
          size="medium"
          @click.prevent="showModal"
          :loading="false"
          plain
          >Добавить юр.лицо</el-button
        >
      </div>
    </div>
    <div class="legal_entity_block_list">
      <div
        class="legal_entity_card_grid"
        v-for="(company, index) in CompanyList"
        :key="index"
      >
        <p v-show="company.name" style="font-weight: bold">
          {{ company.name }}
        </p>
        <p v-show="company.address">Адрес: {{ company.address }}</p>
        <p>
          <span v-show="company.inn">ИНН: {{ company.inn }}</span
          ><span v-show="company.kpp"> / КПП: {{ company.kpp }}</span>
        </p>
        <p v-show="company.ogrn">ОГРН: {{ company.ogrn }}</p>
        <p v-show="company.director">Директор: {{ company.director }}</p>
        <p v-show="company.bik || company.ks || company.rs">
          Cведения для оплаты:
        </p>
        <p v-show="company.bik">БИK: {{ company.bik }}</p>
        <p v-show="company.ks">к/с.: {{ company.ks }}</p>
        <p v-show="company.rs">р/с.: {{ company.rs }}</p>
      </div>
    </div>
    <modal
      ref="modalAddLegalEntity"
      modal-body="AddLegalEntity"
      title="Добавить юр.лицо"
      :show-logo="true"
    >
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  name: "SettingsLegalEntities",
  components: { Modal },
  data() {
    return {
      loading: {},
      show: false,
    };
  },
  methods: {
    showModal: function () {
      this.$refs.modalAddLegalEntity.show = true;
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".legal_entity_block_list",
        text: text,
        spinner: "el-icon-loading",
        background: "rgb(251, 251, 252)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
  },
  computed: {
    CompanyList() {
      return this.$store.getters["Settings/CompanyList"];
    },
    customerName() {
      return this.$store.getters["Main/customerName"];
    },
  },
  mounted() {
    // this.openLoading("Загрузка Юр.лиц...");
    // this.$store.dispatch("Settings/customerCompanyList").finally(() => {
    //   this.closeLoading();
    // });
    this.$emit("loadingEvent", true);
    this.$store.dispatch("Settings/customerCompanyList").finally(() => {
      this.$emit("loadingEvent", false);
      this.show = true;
    });
  },
  destroyed() {
    this.$store.commit("Settings/CompanyList", {});
  },
};
</script>

<style scoped></style>
