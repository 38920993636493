<template>
  <div class="settings_block">
    <div class="settings_block_header">{{ title }}:</div>
    <div class="settings_block_main">
      <settings-nav />
      <div
        class="settings_block_main_right"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgb(251, 251, 252)"
        element-loading-text="Загрузка..."
      >
        <transition name="component-fade" mode="out-in" appear>
          <component :is="setting_component" @loadingEvent="loadingEvent">
            <router-view />
          </component>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import MySettings from "@/components/Settings/SettingsMySettings";
import DeliveryAddress from "@/components/Settings/SettingsDeliveryAddress";
import LegalEntities from "@/components/Settings/SettingsLegalEntities";
import LogoOnLabel from "@/components/Settings/SettingsLogoOnLabel";
import ChangePassword from "@/components/Settings/SettingsChangePassword";
import Users from "@/components/Settings/SettingsUsers";
import SettingsNav from "@/components/Settings/SettingsNav";
export default {
  name: "Settings",
  components: {
    MySettings,
    DeliveryAddress,
    LegalEntities,
    LogoOnLabel,
    ChangePassword,
    Users,
    SettingsNav,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    setting_component() {
      return this.$route.meta.setting_component;
    },
  },
  methods: {
    loadingEvent(value) {
      this.loading = value;
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/sass/settings.sass"
</style>
